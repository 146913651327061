import React from 'react';
import { Accordion, Row } from 'react-bootstrap';
import FormDescription from '../../form/FormDescription';

const ExtraDetail = ({
      installation,
      specification,
      warrenty,
      setInstallation,
      setSpecification,
      setWarrenty
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>Extra Details</Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <FormDescription
                                                description={installation}
                                                setDescription={setInstallation}
                                                title="Specification"
                                          />
                                          <FormDescription
                                                description={specification}
                                                setDescription={
                                                      setSpecification
                                                }
                                                title="Installation"
                                          />
                                          <FormDescription
                                                description={warrenty}
                                                setDescription={setWarrenty}
                                                title="Warrenty"
                                          />
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default ExtraDetail;
