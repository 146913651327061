import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import { ProductIcon } from '../../dashboard';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'product-list',
                              'product-create',
                              'product-edit',
                              'product-show',
                              'category-list',
                              'category-create',
                              'category-edit',
                              'category-show',
                              'brand-list',
                              'brand-edit',
                              'brand-create',
                              'brand-delete',
                              'project-list',
                              'project-create',
                              'project-edit',
                              'project-delete',
                              'service-list',
                              'service-edit',
                              'service-create',
                              'service-delete'
                        ]}
                  >
                        <MenuSeparator>Ecommerce Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'product-list',
                                    'product-create',
                                    'product-edit',
                                    'product-show',
                                    'category-list',
                                    'category-create',
                                    'category-edit',
                                    'category-show',
                                    'brand-list',
                                    'brand-edit',
                                    'brand-create',
                                    'brand-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Products"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/products',
                                          '/dashboard/products/create',
                                          '/dashboard/products/:productId/edit',
                                          '/dashboard/products/:productId/show',
                                          '/dashboard/categories',
                                          '/dashboard/categories/create',
                                          '/dashboard/categories/:categoryId/edit',
                                          '/dashboard/categories/:categoryId/show',
                                          '/dashboard/brands',
                                          '/dashboard/brands/create',
                                          '/dashboard/brands/:brandId/edit',
                                          '/dashboard/brands/:brandId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'brand-list',
                                                'brand-create',
                                                'brand-edit',
                                                'brand-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Brand"
                                                link="/dashboard/brands"
                                                links={[
                                                      '/dashboard/brands',
                                                      '/dashboard/brands/create',
                                                      '/dashboard/brands/:brandId/edit',
                                                      '/dashboard/brands/:brandId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'category-list',
                                                'category-create',
                                                'category-edit',
                                                'category-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category"
                                                link="/dashboard/categories"
                                                links={[
                                                      '/dashboard/categories',
                                                      '/dashboard/categories/create',
                                                      '/dashboard/categories/:categoryId/edit',
                                                      '/dashboard/categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'product-list',
                                                'product-create',
                                                'product-edit',
                                                'product-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/products"
                                                links={[
                                                      '/dashboard/products',
                                                      '/dashboard/products/create',
                                                      '/dashboard/products/:productId/edit',
                                                      '/dashboard/products/:productId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'project-list',
                                    'project-create',
                                    'project-edit',
                                    'project-delete',
                              ]}
                        >
                              <MenuItem
                                    title="Project"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/projects',
                                          '/dashboard/projects/create',
                                          '/dashboard/projects/:projectId/edit',
                                          '/dashboard/projects/:projectId/show',
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'project-list',
                                                'project-create',
                                                'project-edit',
                                                'project-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Project List"
                                                link="/dashboard/projects"
                                                links={[
                                                      '/dashboard/projects',
                                                      '/dashboard/projects/create',
                                                      '/dashboard/projects/:projectId/edit',
                                                      '/dashboard/projects/:projectId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'service-list',
                                    'service-create',
                                    'service-edit',
                                    'service-delete',
                              ]}
                        >
                              <MenuItem
                                    title="Service"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/services',
                                          '/dashboard/services/create',
                                          '/dashboard/services/:serviceId/edit',
                                          '/dashboard/services/:serviceId/show',
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'service-list',
                                                'service-create',
                                                'service-edit',
                                                'service-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Service List"
                                                link="/dashboard/services"
                                                links={[
                                                      '/dashboard/services',
                                                      '/dashboard/services/create',
                                                      '/dashboard/services/:serviceId/edit',
                                                      '/dashboard/services/:serviceId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
