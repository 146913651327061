import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import {
      CardHeader,
      CardLinks,
      CardTitle
} from '../../styles/components/common/Table';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LinkButton } from '../../styles/components/common/Button';
import { toast } from 'react-hot-toast';
import { ItemShow } from '../../components/form';

const PluginShow = () => {
      const { pluginId } = useParams();
      const [plugin, setPlugin] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const token = JSON.parse(localStorage.getItem('token'));

      const loadPlugin = async () => {
            setLoadingData(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/plugins/${pluginId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPlugin(response.data.plugin);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadPlugin();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader>
                                          <CardTitle>Plugin Detail</CardTitle>
                                          <CardLinks>
                                                <LinkButton to="/dashboard/plugins">
                                                      Back
                                                </LinkButton>
                                          </CardLinks>
                                    </CardHeader>
                                    <Card.Body>
                                          {!loadingData ? (
                                                <Row>
                                                      <Col lg={6}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              plugin.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Type"
                                                                        value={
                                                                              plugin.type
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Code"
                                                                        value={
                                                                              plugin.code
                                                                        }
                                                                  />

                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              plugin.status ===
                                                                              1 ? (
                                                                                    <label className="text-success">
                                                                                          Active
                                                                                    </label>
                                                                              ) : (
                                                                                    <label className="text-danger">
                                                                                          Disabled
                                                                                    </label>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created Date"
                                                                        value={
                                                                              plugin.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              plugin.user_name
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center d-flex align-items-center justify-content-center w-100"
                                                            style={{
                                                                  height: '30vh'
                                                            }}
                                                      >
                                                            <Spinner />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PluginShow;
